
import { mapActions } from 'vuex'
import subscribeNewsletter from '~/apollo/mutation/subscribeEmailToNewsletter'

export default {
  name: 'SubscriptionPopup',
  props: {
    pageType: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    tokenRecaptcha: '',
    showSubscription: false,
    newsletter: {
      email: '',
      name: '',
      gender: '',
      telephone: '',
      origin: 'popup_newsletter'
    },
    waitSubmit: false,
    message: {
      status: false,
      type: '',
      content: ''
    }
  }),
  mounted() {
    const subscriptionCookie = this.$cookies.get('e-lens-subscription')
    const alreadyClosed = subscriptionCookie !== null && subscriptionCookie === '1'
    if (this.pageType === 'product' || this.pageType === 'category' || this.pageType === 'search' || (this.pageType === 'landing' && this.$route.path !== '/')) {
      setTimeout(() => {
        if (alreadyClosed === false) {
          const expires = new Date()
          expires.setTime(expires.getTime() + (1 * 12 * 60 * 60 * 1000))
          this.$cookies.set('e-lens-subscription', 1, expires)
          window.scrollTo(0, 0)
          this.showSubscription = true
          this.$emit('fixedHeaderPop', true)
        }
      }, 120000)
    } else if (this.pageType === 'landing' && this.$route.path === '/') {
      setTimeout(() => {
        if (alreadyClosed === false) {
          const expires = new Date()
          expires.setTime(expires.getTime() + (1 * 12 * 60 * 60 * 1000))
          this.$cookies.set('e-lens-subscription', 1, expires)
          window.scrollTo(0, 0)
          this.showSubscription = true
          this.$emit('fixedHeaderPop', true)
        }
      }, 5000)
    }
  },
  methods: {
    ...mapActions({
      toggleNoScroll: 'layout/AANoScroll'
    }),
    async handleSubmit() {
      try {
        await this.$recaptcha.init()
      } catch (e) {
        console.log(e)
      }
      this.tokenRecaptcha = await this.$recaptcha.execute('newsletter')
      const isValid = await this.$validator.validateAll()
      if (isValid && this.tokenRecaptcha !== '') {
        this.waitSubmit = true
        try {
          await this.$apollo.mutate({
            mutation: subscribeNewsletter,
            variables: {
              email: this.newsletter.email,
              gender: this.newsletter.gender,
              telephone: this.newsletter.telephone,
              from_source: 'popup_newsletter'
            },
            context: {
              headers: {
                'x-recaptcha': `${this.tokenRecaptcha}`
              }
            }
          }).then((data) => {
            this.message.status = true
            this.message.type = 'success'
            this.message.content = 'E-mail cadastrado com sucesso!'
            try {
              this.$recaptcha.reset()
            } catch (e) {
              console.log(e)
            }
            setTimeout(() => {
              this.tokenRecaptcha = ''
              this.waitSubmit = false
              this.closeSubscription()
              this.newsletter.email = ''
              this.newsletter.gender = ''
              this.newsletter.telephone = ''
              this.$validator.pause()
              this.$nextTick(() => {
                this.$validator.errors.clear()
                this.$validator.fields.items.forEach(field => field.reset())
                this.$validator.fields.items.forEach(field => this.errors.remove(field))
                this.$validator.resume()
              })
              this.message.status = false
              this.message.type = ''
              this.message.content = ''
            }, 5000)
          }).catch(({ graphQLErrors }) => {
            this.waitSubmit = false
            this.message.status = true
            this.message.type = 'error'
            if (graphQLErrors[0].message !== 'Este endereço de e-mail já está inscrito.') {
              this.message.content = 'Não foi possível cadastrar e-mail, tente novamente.'
            } else {
              this.message.content = graphQLErrors[0].message
            }
          })
        } catch (error) {
          console.log(error)
        }
        setTimeout(() => {
          this.message.status = false
          this.message.type = ''
          this.message.content = ''
        }, 5000)
      }
    },
    closeSubscriptionModal() {
      const expires = new Date()
      expires.setTime(expires.getTime() + (1 * 12 * 60 * 60 * 1000))
      this.$cookies.set('e-lens-subscription', 1, expires, '/')
      this.showSubscription = false
      this.toggleNoScroll(false)
      this.$emit('fixedHeaderPop', false)
    },
    closeSubscription() {
      const expires = new Date()
      expires.setTime(expires.getTime() + (365 * 24 * 60 * 60 * 1000))
      this.$cookies.set('e-lens-subscription', 1, expires, '/')
      this.showSubscription = false
      this.toggleNoScroll(false)
      this.$emit('fixedHeaderPop', false)
    }
  }
}
